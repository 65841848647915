import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Menu from 'components/Menu';
import MenuTrigger from 'components/Menu/MenuTrigger';
import Footer from 'components/Footer';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { scrollTo } from 'components/SmoothScroll';
import { getMenuOpen } from 'App/redux/selectors';
import useActions from 'hooks/useActions';
import { setMenuOpen } from 'App/redux/actions';
import useHash from 'hooks/useHash';
import Gites from './sections/Gites';
import Insolite from './sections/Insolite';
import Camping from './sections/Camping';
import Velo from './sections/Velo';
import Home from './sections/Home';
import Services from './sections/Services';

import './web.scss';
import Discover from './sections/Discover';
import About from './sections/About';
import Contact from './sections/Contact';

const positions = [
  'outer',
  'outer',
  'inner',
  'outer',
  'outer',
  'inner',
  'inner',
  'inner',
  'outer',
  'outer',
  'outer',
  'outer',
  'outer',
  'inner',
  'outer',
];

const { containerId } = window.CONFIG;
const effect = 10;
const position = positions[effect - 1];

const sections = [
  {
    id: 'accueil',
    Component: Home,
  },
  {
    id: 'a-propos',
    Component: About,
  },
  {
    id: 'gites',
    Component: Gites,
  },
  {
    id: 'insolite',
    Component: Insolite,
  },
  {
    id: 'camping',
    Component: Camping,
  },
  {
    id: 'velo',
    Component: Velo,
  },
  {
    id: 'services',
    Component: Services,
  },
  {
    id: 'decouvrir',
    Component: Discover,
  },
  {
    id: 'contact',
    Component: Contact,
  },
];

const Layout = () => {
  const menuOpen = useSelector(getMenuOpen);
  const onSetMenuOpen = useActions(setMenuOpen);

  const hash = useHash();

  useEffect(() => {
    if (hash) {
      if (!hash.startsWith('#action')) {
        setTimeout(() => {
          scrollTo(hash.substring(1, hash.length));
        }, 200);
      }
    }
  }, [hash]);

  const handleClose = () => {
    if (menuOpen) {
      onSetMenuOpen(false);
    }
  };

  return (
    <div
      className={classNames('st-container layout light', `st-effect-${effect}`, {
        'st-menu-open': menuOpen,
      })}
    >
      {position === 'outer' && <Menu />}

      <div className={classNames('st-pusher')} onClick={handleClose}>
        {position === 'inner' && <Menu />}

        <div id={containerId} className="st-content">
          {sections.map(({ id, Component }) => (
            <Component key={id} />
          ))}
          <Footer />
        </div>
        <MenuTrigger />
      </div>
      <Modal />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  effect: PropTypes.string.isRequired,
};

export default Layout;
