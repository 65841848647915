import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import Section, { Block, Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';
import { Formatter } from 'i18n';
import useWindowSize from '../../../../hooks/useWindowSize';

import './discover.scss';
import { getImgsList } from '../../../../utils';

const valjoly = 'https://valjoly.com/station/';
const rando = 'http://www.tourisme-avesnois.com/voir-faire/randonnees#component=marqueBlanche&amp;task=searchRandosListe&amp;duree=0&amp;typeLocomotion=0&amp;difficulte=0&amp;loc=Willies%20%2859740%29&amp;idPro=1782857';
const rando2 = 'https://www.visorando.com/randonnee-nord.html';
const rando3 = 'https://eurovelo3.fr/eurovelo3/avesnois-thierache/maubeuge-liessies/';
const liessies = 'https://www.youtube.com/watch?v=xhEK7JgR0kg';
const avesnois = 'http://www.parc-naturel-avesnois.fr/';
const belgique1 = 'https://walloniebelgiquetourisme.fr/fr/produit/syndicat-dinitiative-maison-de-tourisme/maison-du-tourisme/maison-du-tourisme-de-la-botte-du-hainaut/15447';
const belgique2 = 'https://officetourismesivryrance.wordpress.com/';

const restos = [
  { id: 'restau1', url: 'https://www.logishotels.com/fr/hotel/logis-hotel-du-chateau-de-la-motte-503?partid=1535' },
  { id: 'restau2', url: 'https://www.google.com/search?q=la+bergerie+liessies+menu&newwindow=1&sca_esv=a51a2b9a6f31c2a3&sca_upv=1&authuser=0&sxsrf=ACQVn0_nA-rH5wHNx1ioYmTAxOU3AlIhpw%3A1712422538977&ei=in4RZp-jO5eukdUP88mUmAs&oq=la+bergerie+liessies&gs_lp=Egxnd3Mtd2l6LXNlcnAiFGxhIGJlcmdlcmllIGxpZXNzaWVzKgIIADIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIKEAAYRxjWBBiwAzIREAAYgAQYigUYkgMYuAQYsAMyHBAuGIAEGIoFGEMYxwEYrwEYyAMYsAMYjgXYAQEyIhAuGIAEGIoFGEMYxwEYrwEYmAUYngUYmQUYyAMYsAPYAQFI5QtQAFgAcAF4AZABAJgBAKABAKoBALgBAcgBAJgCAaACCpgDAIgGAZAGC7oGBAgBGAiSBwExoAcA&sclient=gws-wiz-serp' },
  { id: 'restau4', url: 'https://www.tourisme-avesnois.com/preparer-son-sejour/ou-manger/6048687_cafe-rando-nord-la-forestiere/' },
  { id: 'restau5', url: 'https://www.willysfrites.fr/' },
  { id: 'restau7', url: 'https://g.co/kgs/3DX91XG' },
  { id: 'restau6', url: 'https://www.tourisme-avesnois.com/preparer-son-sejour/ou-manger/un-restaurant-a-proximite/r/territoires/communaute_de_communes_du_sud_avesnois/p/2/' },
];

const liessiesImgs = getImgsList('liessies', 7);
const randoImgs = getImgsList('rando', 8);
const belgiqueImgs = getImgsList('belgique', 6);
const museeImgs = getImgsList('musee', 5);
const valjolyImgs = getImgsList('valjoly', 6);

const Restau = ({ id, url }) => {
  const ref = useRef();
  const size = useWindowSize();
  const [style, setStyle] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (size && ref.current) {
      setStyle({
        height: ref.current.offsetWidth * (9 / 16),
        backgroundImage: `url('${window.CONFIG.BASE_URL}/images/restos/${id}.jpg')`,
      });
    }
  }, [id, size]);

  const text = useMemo(() => t(`discover.restaurants.${id}`), [id, t]);

  return (
    <p ref={ref} className="restau" style={style}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </p>
  );
};

const Discover = () => {
  const { t } = useTranslation();

  const musees = useMemo(() => [
    { id: 'ecomusee', href: 'https://ecomusee-avesnois.fr/' },
    { id: 'musverre', href: 'https://musverre.fr/' },
    { id: 'trelon', href: 'https://www.chateaudetrelon.com/' },
    { id: 'parlante', href: 'https://www.tourisme-avesnois.com/preparer-son-sejour/la-parenthese-patrimoine-se-cultiver/6058912_musee-de-la-machine-parlante/' },
  ].map(({ id, href }) => ({
    href,
    ...['title', 'text', 'link'].reduce(
      (acc, value) => ({ ...acc, [value]: t(`discover.culture.${id}.${value}`) }), {},
    ),
  })), [t]);

  return (
    <Section className="section-decouvrir">
      <Waypoint>
        <Title transKey="discover.pageTitle" />
        <div className="lead list animated">
          <Block title={t('discover.valjoly.title')} imgs={valjolyImgs}>
            <p>
              <Formatter transKey="discover.valjoly.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>
              <a href={valjoly} target="_blank" rel="noopener noreferrer">{t('discover.valjoly.discover')}</a>
            </p>
          </Block>

          <Block title={t('discover.liessies.title')} imgs={liessiesImgs}>
            <p>
              <Formatter transKey="discover.liessies.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>
              <a href={liessies} target="_blank" rel="noopener noreferrer">{t('discover.liessies.discover')}</a>
            </p>
            <p>
              <a href={avesnois} target="_blank" rel="noopener noreferrer">{t('discover.liessies.avesnois')}</a>
            </p>
          </Block>

          <Block title={t('discover.restaurants.title')}>
            <div className="restau-container">
              {restos.map(({ id, url }) => (
                <Restau
                  key={id}
                  id={id}
                  url={url}
                />
              ))}
            </div>
          </Block>

          <Block title={t('discover.rando.title')} imgs={randoImgs}>
            <p>
              <Formatter transKey="discover.rando.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>
              <a href={rando} target="_blank" rel="noopener noreferrer">{t('discover.rando.all')}</a>
            </p>
            <p>
              <a href={rando2} target="_blank" rel="noopener noreferrer">{t('discover.rando.find')}</a>
            </p>
            <p>
              <a href={rando3} target="_blank" rel="noopener noreferrer">{t('discover.rando.velo')}</a>
            </p>
          </Block>

          <Block title={t('discover.belge.title')} imgs={belgiqueImgs}>
            <p>
              <Formatter transKey="discover.belge.p1">
                1
                <span className="highlight">1</span>
              </Formatter>
            </p>
            <p>
              <a href={belgique1} target="_blank" rel="noopener noreferrer">{t('discover.belge.link1')}</a>
            </p>
            <p>
              <a href={belgique2} target="_blank" rel="noopener noreferrer">{t('discover.belge.link2')}</a>
            </p>
          </Block>

          <Block title={t('discover.culture.title')} imgs={museeImgs}>
            {musees.map(({
              title, text, link, href,
            }) => (
              <p>
                <span className="highlight">{title}</span>
                {text && (
                <div>
                  {text}
                </div>
                )}
                <div><a href={href} target="_blank" rel="noopener noreferrer">{link}</a></div>
              </p>
            ))}

          </Block>
        </div>
      </Waypoint>
    </Section>
  );
};

export default Discover;
