import React, { useMemo } from 'react';
import Section, { Block, Title } from 'components/Section';
import Waypoint from 'components/Waypoint';
import { useTranslation } from 'react-i18next';

import './gites.scss';
import Booking from 'components/Booking';
import BookingLink from 'components/Booking/BookingLink';

import { getImgsList } from '../../../../utils';

// https://iteaburo.elloha.com/d699b4b4-4185-4a7a-91e2-321c3669f190/7f2c0ccd-f0ce-4077-8fae-0489864b6733.ics

function getTexts(giteId, t, i18n, items = []) {
  const key = `gites.${giteId}.p${items.length + 1}`;

  if (i18n.exists(key)) {
    return getTexts(giteId, t, i18n, [...items, key]);
  }
  return items;
}

const gites = [
  {
    id: 'gite1',
    imgs: getImgsList('gite_2', 12),
    booking: 'gite4910',
    bookingLink: 'https://tinyurl.com/26t8ob6z',
  },
  {
    id: 'gite2',
    imgs: getImgsList('gite_4', 10),
    booking: 'gite2',
    bookingLink: 'https://tinyurl.com/2fhxaqjv',
  },
];

const Gite = ({ gite }) => {
  const { t, i18n } = useTranslation();

  const texts = useMemo(() => getTexts(gite.id, t, i18n), [gite.id, i18n, t]);

  return (
    <Block title={t(`gites.${gite.id}.title`)} imgs={gite.imgs}>
      {texts.map((text) => <p>{t(text)}</p>)}

      <Booking what={gite.booking} />
      <BookingLink url={gite.bookingLink} />
    </Block>
  );
};

const Gites = () => (
  <Section className="section-gites">
    <Waypoint>
      <Title transKey="gites.pageTitle" />
      <div className="lead list animated">
        {gites.map((gite) => <Gite key={gite.id} gite={gite} />)}
      </div>
    </Waypoint>
  </Section>
);

export default Gites;
